<template>
  <div class="modular-solution">
    <p class="title">
      <span>解决方案</span>
      <span>Solution</span>
    </p>

    <ul class="solution-list" @click="$router.push('/list')">
      <li class="solution-item">数据治理</li>
      <li class="solution-item">大数据实训基地</li>
      <li class="solution-item">智慧校园</li>
      <li class="solution-item">智慧教育平台</li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'solution',
    components: {},
    props: {},
    data() {
      return {}
    },
    methods: {},
  }
</script>

<style lang="less">
  .modular-solution {
    height: 260px;
    background: #fff;
    .title {
      text-align: center;
      margin-top: 24px;
      color: #333333;

      span:first-child {
        font-size: 18px;
        font-weight: 600;
        margin-right: 8px;
      }
      span:last-child {
        opacity: 0.9;
        font-size: 15px;
      }
    }

    .solution-list {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 25px;
      padding: 5px;
    }
    .solution-item {
      width: 50%;
      height: 81px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      align-items: center;
      background-size: cover;
      color: #fff;
      opacity: 0.9;
    }
    .solution-item:nth-child(even) {
      padding-left: 63px;
    }
    .solution-item:nth-child(odd) {
      padding-left: 68px;
    }

    .solution-item:first-child {
      background-image: url(../../assets/home/solution/governance.svg);
    }
    .solution-item:nth-child(2) {
      background-image: url(../../assets/home/solution/data.svg);
    }

    .solution-item:nth-child(3) {
      background-image: url(../../assets/home/solution/school.svg);
    }
    .solution-item:nth-child(4) {
      background-image: url(../../assets/home/solution/platform.svg);
    }
  }
</style>
