<template>
  <div class="modular-product">
    <p class="title">
      <span>产品</span>
      <span>Product</span>
    </p>

    <ul class="product-list">
      <li class="product-item" v-for="item in products" :key="item.id" @click="linkTarget(item.linkref)">
        <div class="icon">
          <img :src="baseImageUrl+item.pic+'?m='+getRandom()" v-if="item.pic">
          <img src="/static/default-image/default-product.png" v-else>
        </div>
        <div class="name">{{item.title}}</div>
      </li>
    </ul>

    <div class="show-more">
      <template v-if="products.length>6">
        <span v-if="showMore" @click="showMoreContent">展开更多<van-icon name="arrow-down"></van-icon></span>
        <span v-else @click="hideMoreContent">隐藏更多<van-icon name="arrow-up"></van-icon></span>
      </template>
    </div>
  </div>
</template>

<script>
  import { getProductList } from "@/api/home.js";
  export default {
    name: 'product',
    components: {},
    props: {},
    data() {
      return {
        productList: [],
        products: [],
        showMore: false,
        baseImageUrl: baseImageUrl,
        id: homeProductListId
      }
    },
    created() {
      this.getProducts();
    },
    methods: {
      // 获取当前分钟数
      getRandom() {
        return new Date().getMinutes()
      },
      async getProducts() {
        const params = {
          classids: this.id,
          limit: 8,
          page: 1
        }
        try {
          const { data: { status, msg, result } } = await getProductList(params);
          if (status === 1 && msg === "ok") {
            this.productList = result;
            if (result.length > 6) {
              this.showMore = true
              this.products = this.productList.slice(0, 6)
            } else {
              this.showMore = false
              this.products = this.productList
            }
          }
        } catch (error) {
          console.log(error);
        }
      },
      linkTarget(url) {
        const isId = /^[0-9]*$/;
        const isUrl = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
        if (isId.test(url)) {
          this.$router.push({ path: "/deadils", query: { id: url } })
        } else if (isUrl.test(url)) {
          // window.open(url)
          window.location.href = url
        }
      },
      // 显示更多内容
      showMoreContent() {
        this.showMore = false
        this.products = this.productList
      },
      // 隐藏更多内容
      hideMoreContent() {
        this.showMore = true
        this.products = this.productList.slice(0, 6)
      }
    },
  }
</script>

<style lang="less">
  .modular-product {
    padding: 20px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: url(../../assets/home/product/product-background.png);

    .title {
      text-align: center;
      color: #333333;
      margin-bottom: 24px;

      span:first-child {
        font-size: 18px;
        font-weight: 600;
        margin-right: 8px;
      }
      span:last-child {
        opacity: 0.9;
        font-size: 15px;
      }
    }

    .product-list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      flex: 1;
    }

    .product-item {
      width: 33.3%;
      height: 50%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        width: 74px;
        height: 76px;
      }

      .name {
        font-size: 14px;
        color: #413b56;
        text-align: center;
      }
    }

    .product-item:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }

    .show-more {
      color: #989aa1;
      font-size: 13px;
      text-align: center;
      margin-top: 20px;

      .van-icon {
        margin-left: 6px;
      }
    }
  }
</style>
