<template>
  <div class="modular-cases">
    <p class="title">
      <span>经典案例</span>
      <span>Classic case</span>
    </p>

    <div class="content">
      <van-tabs v-model="activeTab" title-active-color="#333333" title-inactive-color="#606266" :class="!activeTab?'active':'not-activea'">
        <van-tab title="高校">
          <van-swipe :autoplay="0" touchable>
            <van-swipe-item v-for="array,i in higher" :key="i">
              <ul class="cases-list">
                <li v-for="item,index in array" :key="item.id" class="cases-item">
                  <div class="icon">
                    <img :src="baseImageUrl+item.pic+'?m='+getRandom()" v-if="item.pic">
                    <img src="/static/default-image/default-case.png" v-else>
                  </div>
                  <van-icon name="plus" v-if="isShowIcon(index+1)"></van-icon>
                </li>
              </ul>
            </van-swipe-item>
          </van-swipe>
        </van-tab>
        <van-tab title="普教">
          <van-swipe :autoplay="0" touchable>
            <van-swipe-item v-for="array,i in ordinary" :key="i">
              <ul class="cases-list">
                <li v-for="item,index in array" :key="item.id" class="cases-item">
                  <div class="icon">
                    <img :src="baseImageUrl+item.pic+'?m='+getRandom()" v-if="item.pic">
                    <img src="/static/default-image/default-case.png" v-else>
                  </div>
                  <van-icon name="plus" v-if="isShowIcon(index+1)"></van-icon>
                </li>
              </ul>
            </van-swipe-item>
          </van-swipe>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
  import { getArticleList } from "@/api/article.js";
  export default {
    name: 'cases',
    components: {},
    props: {},
    data() {
      return {
        activeTab: 0,
        // 高教
        higherId: higherId,
        higher: [],
        // 普教
        ordinaryId: ordinaryId,
        ordinary: [],
        // 基础图片地址
        baseImageUrl: baseImageUrl,
      }
    },
    created() {
      this.getHigher();
      this.getOrdinary();
    },
    methods: {
      switchTab(value) {
        this.activeTab = value;
      },
      isShowIcon(item) {
        return (item <= 3)
      },
      // 获取高教
      async getHigher() {
        try {
          const { data } = await getArticleList({
            classids: this.higherId,
            limit: 20,
            page: 1
          });

          if (data.status && data.msg === "ok") {
            this.higher = this.cutting(data.result, 8);
          }
        } catch (error) {
          console.log(error);
        }
      },
      // 获取普教
      async getOrdinary() {
        try {
          const { data } = await getArticleList({
            classids: this.ordinaryId,
            limit: 20,
            page: 1
          });

          if (data.status && data.msg === "ok") {
            this.ordinary = this.cutting(data.result, 8);
          }
        } catch (error) {
          console.log(error);
        }
      },
      // 获取当前分钟数
      getRandom() {
        return new Date().getMinutes()
      },
      // 分割数组
      cutting(list, length) {
        if (list && list.length) {
          const array = []
          let index = 0
          while (index < list.length) {
            array.push(list.slice(index, index += length));
          }
          return array
        }
      }
    },
  }
</script>

<style lang="less">
  .modular-cases {
    height: 330px;
    padding: 15px;
    background: #f3f4f7;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
      color: #333333;
      padding: 12px 0;

      span:first-child {
        font-size: 18px;
        font-weight: 600;
        margin-right: 8px;
      }
      span:last-child {
        opacity: 0.9;
        font-size: 15px;
      }
    }

    .content {
      flex: 1;
      border-radius: 12px;
      overflow: hidden;
    }

    .van-tabs__wrap {
      border-bottom: none;
    }
    .not-activea {
      .van-tabs__nav {
        background-image: url(../../assets/home/cases/tab-background.jpg);
      }
    }

    .active {
      .van-tabs__nav {
        background-image: url(../../assets/home/cases/active-tab-background.jpg);
      }
    }

    .van-tabs__nav {
      height: 45px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-size: cover;
      transition: background-image 0.7s;
      padding-bottom: 0;

      .van-tabs__line {
        display: none;
      }

      .van-tab {
        width: 50%;
        text-align: center;
        color: #606266;
        font-size: 16px;
        opacity: 0.9;
      }

      .van-tab--active {
        opacity: 1;
      }
    }

    .van-swipe {
      height: 200px;
      width: 100%;
      background: #fff;
    }

    .van-swipe-item {
      padding-bottom: 20px;
    }

    .van-swipe__indicator {
      width: 8px;
      height: 4px;
      display: inline-block;
      background: linear-gradient(282deg, #27b3fc 0%, #117cf8 100%);
      border-radius: 3px;
      opacity: 0.16;
    }

    .van-swipe__indicator.van-swipe__indicator--active {
      width: 11px;
      background: linear-gradient(282deg, #27b3fc 0%, #117cf8 100%);
      opacity: 1;
    }

    .cases-list {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-content: start;
      flex-wrap: wrap;
    }

    .cases-item {
      width: 25%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .icon {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        overflow: hidden;
      }

      .van-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
        color: #5c5c66;
        user-select: none;
        pointer-events: none;
        font-size: 10px;
        opacity: 0.3;
      }
    }
  }
</style>
