<template>
  <div class="page page-home">
    <modular-head :image="headImage">
      <template v-slot:icon>
        <van-icon name="wap-nav" @click="showPopup"></van-icon>
      </template>
      <p class="title">{{ viewpoint.title }}</p>
      <p class="describe">{{ viewpoint.info }}</p>
      <button class="details" @click="gotoDetailPage({ id: viewpoint.id })">了解详情</button>
    </modular-head>

    <product></product>

    <solution></solution>

    <service></service>

    <cases></cases>

    <van-popup v-model="control" position="right" round closeable @closed="resetCollapse">
      <van-collapse v-model="outerActive" class="outter-collapse" accordion :border="false">
        <van-collapse-item :title="item.name" :name="index" :icon="icons[index]" v-for="item, index in columnList" :key="item.id" :border="false">
          <template v-if="item.children.length">
            <van-collapse v-model="innerActive" class="inner-collapse" accordion :border="false">
              <van-collapse-item :title="ele.cname" :name="i" :key="ele.id" v-for="ele, i in item.children" :border="false">
                <template v-if="ele.children.length">
                  <p class="text-control" v-for="title in ele.children" :key="title.id" @click="gotoDetailPage(title)">{{ title.title }}</p>
                </template>
                <p v-else>暂无数据</p>
              </van-collapse-item>
            </van-collapse>
          </template>
          <p v-else>暂无数据</p>
        </van-collapse-item>
        <van-collapse-item title="案例" name="3" :icon="require('@/assets/home/popup/cases.svg')" :border="false" @click.native="gotoPage('/cases')">
        </van-collapse-item>
        <van-collapse-item title="服务" name="4" :icon="require('@/assets/home/popup/service.svg')" :border="false" @click.native="gotoPage('/service')">
        </van-collapse-item>
        <van-collapse-item title="动态" name="5" :icon="require('@/assets/home/popup/dynamic.svg')" :border="false" @click.native="gotoPage('/dynamic')">
        </van-collapse-item>
      </van-collapse>
    </van-popup>
  </div>
</template>

<script>
import modularHead from '@/modular/head/index.vue'
import product from './product.vue'
import solution from './solution.vue'
import service from './service.vue'
import cases from './cases.vue'
import { getColumnArtice } from "@/api/home.js";
import { getArticleList } from "@/api/article.js";
export default {
  name: 'page-home',
  components: {
    modularHead,
    cases,
    service,
    solution,
    product
  },
  data() {
    return {
      viewpoint: {},
      viewpointsId: viewpointsId,
      control: false,
      outerActive: "",
      innerActive: "",
      columnList: columnList,
      icons: [
        require('@/assets/home/popup/product.svg'),
        require('@/assets/home/popup/solution.svg'),
      ],
      headImage: ""
    }
  },
  created() {
    this.init();
    this.getViewpoint();
  },
  methods: {
    // 初始化
    init() {
      this.columnList.forEach((item, index) => {
        this.getColumnArtice(item, index)
      });
    },
    // 显示弹窗
    showPopup() {
      this.control = true;
    },
    // 获取栏目文章
    async getColumnArtice(item, index) {
      const { id } = item
      try {
        const { data } = await getColumnArtice({ classid: id });
        if (data) {
          if ("showTitle" in item) {
            for (let i = 0; i < data.length; i++) {
              const { children } = data[i];
              if (children) {
                for (let j = 0; j < children.length; j++) {
                  children[j].showTitle = "no";
                }
              }
            }
          }
          this.columnList[index].children = data;
        }
      } catch (error) {

      }
    },
    // 获取行业观点
    async getViewpoint() {
      try {
        const { data } = await getArticleList({
          classids: this.viewpointsId,
          limit: 10,
          page: 1
        });

        if (data.status && data.msg === "ok") {
          const article = data.result[0]
          if (article.pic && article.pic.length) {
            this.headImage = article.pic
          }
          this.viewpoint = article;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 重置折叠面板
    resetCollapse() {
      this.outerActive = "";
      this.innerActive = "";
    },
    // 去主页
    gotoHome() {
      this.$router.push("/")
    },
    //去详情页
    gotoDetailPage(item) {
      const { linkref, id, showTitle } = item
      console.log(item);
      if (linkref) {
        window.location.href = linkref
      } else if (id) {
        const query = { id: id }
        if (showTitle === "no") {
          query.title = "no"
        }
        this.$router.push({ path: "/deadils", query: query })
      }
    },
    gotoPage(path) {
      this.$router.push(path)
    },
  }
}
</script>

<style lang="less">
.page-home {
  .modular-head {
    .title {
      width: 65%;
      text-align: center;
      font-size: 24px;
      color: #ffffff;
    }

    .describe {
      margin-top: 10px;
      opacity: 0.9;
      font-size: 14px;
      color: #ffffff;
    }

    .details {
      padding: 4px 22px;
      background: linear-gradient(90deg, #0b6ff7 0%, #2ebaf9 100%);
      border-radius: 15px;
      outline: none;
      border: none;
      margin-top: 11px;
      font-size: 14px;
    }
  }

  .outter-collapse::after {
    position: static;
  }

  .outter-collapse {
    color: #333;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding-right: 20px;

    .van-collapse-item__content {
      padding: 0 0 0 24px;
      color: #666666;
    }
  }

  .outter-collapse>.van-collapse-item>.van-collapse-item__title {
    height: 45px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .van-collapse-item__title {
    line-height: 1;
    color: inherit;
  }

  .van-cell__left-icon,
  .van-cell__right-icon {
    height: auto;
  }

  .van-cell__left-icon {
    margin-right: 8px;
  }

  .inner-collapse {
    color: #666666;

    .van-collapse-item {
      border-bottom: 1px solid #e8e8e8;
    }

    .van-collapse-item__title {
      justify-content: space-between;
      align-items: center;
      height: 45px;
      padding: 0;
    }

    .van-collapse-item__content {
      padding: 0 0 0 25px;

      p {
        padding: 10px 0;
        width: 100%;
      }
    }
  }
}
</style>
