import http from '@/utils/request.js'

// 获取栏目数据
export function getColumnData(data) {
  return http({
    url: "/class",
    method: "POST",
    data
  })
}

// 获取二级栏目和文章
export function getColumnArtice(data) {
  return http({
    url: "/headerlist",
    method: "POST",
    params: data
  })
}

// 获取二级栏目和文章
export function getProductList(data) {
  return http({
    url: "/artlist",
    method: "POST",
    params: data
  })
}