<template>
  <div class="modular-head" :style="{ 'background-image': 'url(' + image + ')' }">
    <div class="modular-head-title">
      <label @click="gotoHome">
        <img src="../../assets/head/icon.svg">
        <span class="separate"></span>
        <span class="info">康赛信息</span>
      </label>
      <slot name="icon"></slot>
    </div>
    <div class="modular-head-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import defaultImage from "@/assets/head/head-background.png"
export default {
  name: 'modular-head',
  props: {
    image: {
      type: String,
      default: defaultImage
    }
  },
  data() {
    return {}
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    }
  },
}
</script>

<style lang="less">
.modular-head {
  height: 220px;
  background-size: cover;
  padding: 13px 15px;
  color: #fff;

  .modular-head-title {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 25px;
      height: 25px;
    }

    .separate {
      width: 1px;
      height: 15px;
      background: #ffffff;
      opacity: 0.8;
      margin: 0 8px;
    }

    .info {
      font-weight: 600;
      color: #ffffff;
      font-size: 14px;
    }

    .van-icon {
      margin-left: auto;
      font-size: 24px;
    }
  }

  .modular-head-content {
    height: calc(100% - 25px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
