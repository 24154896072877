<template>
  <div>
    <van-nav-bar :title="$route.meta.title" left-arrow @click-left="goBack" v-if="!isHome" fixed> </van-nav-bar>

    <router-view></router-view>

    <modular-footer></modular-footer>

    <div class="copyright">
      <p>成都康赛信息技术有限公司 版权所有</p>
      <p>
        <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备11006444号-1</a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010602000933">川公网安备51010602000933号</a>
      </p>
    </div>
  </div>
</template>
<script>
import pageHome from '@/views/home/index.vue'
import modularFooter from '@/modular/footer/index.vue'
export default {
  name: 'App',
  components: {
    pageHome,
    modularFooter
  },
  data() {
    return {}
  },
  created() {
    this.handleIsHome();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    handleIsHome() {
      if (this.$route.path === "/") {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
  },
  watch: {
    $route() {
      this.handleIsHome();
    }
  }
}
</script>
<style lang="less">
.page:not(.page-home) {
  padding-top: 40px;
}

.copyright {
  padding: 15px 0;
  background: #262a39;
  font-size: 12px;
  color: #fff;
  text-align: center;

  p {
    margin-top: 10px;
  }

  p:first-child {
    margin-top: 0;
  }

  a {
    color: #fff;
  }

  a:last-child {
    margin-left: 5px;
  }
}
</style>
