<template>
  <div class="modular-footer">
    <div class="menu">
      <router-link to="/about" active-class="active">关于康赛 </router-link>|
      <router-link to="/recruit" active-class="active">招贤纳士 </router-link>|
      <!-- <router-link to="/join" active-class="active">招商加盟 </router-link>| -->
      <router-link to="/law" active-class="active">法律声明</router-link>
    </div>

    <div class="info-list">
      <div class="info-item">
        <img src="../../assets/footer/location.svg" />
        <span>地址：成都市一环路东一段电子信息产业大厦310室</span>
      </div>
      <div class="info-item">
        <img src="../../assets/footer/postal-code.svg" class="postal">
        <span>邮编：610054</span>
      </div>
      <div class="info-item">
        <img src="../../assets/footer/hotline.svg">
        <span>热线：4000-501-758</span>
      </div>
      <div class="info-item">
        <img src="../../assets/footer/telphone.svg">
        <span>电话：028-83202027</span>
      </div>
      <div class="info-item">
        <img src="../../assets/footer/email.svg" class="email">
        <span>邮箱：tangxuefei@comsys.net.cn</span>
      </div>
      <div class="info-item">
        <img src="../../assets/footer/route.svg" class="route">
        <span>公交路线：27路、42路、45路、65路</span>
      </div>
    </div>

    <!-- <van-image :src="require('@/assets/footer/code.png')" @click="showQRcode" class="qr-code"></van-image> -->
  </div>
</template>

<script>
  import { ImagePreview } from 'vant';
  export default {
    name: 'modular-footer',
    components: {},
    props: {},
    data() {
      return {
      }
    },
    methods: {
      showQRcode() {
        ImagePreview([require('@/assets/footer/code.png')])
      }
    },
  }
</script>

<style lang="less">
  .modular-footer {
    height: 286px;
    background: url(../../assets/footer/background.png);
    background-size: cover;
    padding-left: 20px;
    padding-top: 30px;
    position: relative;
    color: #fff;

    .menu {
      font-size: 16px;
      a {
        margin-right: 4px;
        color: #ffffff;
      }

      .active {
        color: #2998fe;
      }
    }
    .info-list {
      margin-top: 30px;

      .info-item {
        font-size: 13px;
        margin-bottom: 13px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }

        img.route {
          width: 18px;
          height: 17px;
        }

        img.email {
          width: 16px;
          height: 12px;
        }

        img.postal {
          width: 15px;
          height: 12px;
        }
      }
    }

    .qr-code {
      position: absolute;
      bottom: 20px;
      right: 15px;
      width: 60px;
      height: 60px;
    }
  }
</style>
