import axios from 'axios'
axios.defaults.withCredentials = true
// 创建实例
const service = axios.create({
  // 配置基地址
  baseURL: process.env.NODE_ENV === "development" ? "/api" : '/public',
  headers: {
    'Cache-Control': 'no-cache',
    ['Pragma']: 'no-cache',
    'refeod': 'ajax'
  },
  // 配置请求超时时间
  // timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截器
// service.interceptors.response.use(
//   function (response) {
//     // 响应状态码200，响应成功
//     if (response.status === 200) {

//       // 操作码200
//       if (response.data.code === 200) {
//         return response.data.data
//       }
//       // 操作码500
//       else if (response.data.code === 500) {
//         return Promise.reject(response.data.msg)
//       }
//       // 操作码401
//       else if (response.data.code === 401) {
//         // 跳转到登录页
//         return Promise.reject(response.data.msg)
//       }
//     }
//   },
//   function (error) {
//     // 响应状态码404，地址错误
//     if (error.response.status === 404) {
//       return Promise.reject('请求地址错误')
//     }
//     // 响应状态码500，服务器错误
//     else if (error.response.status === 401) {
//       // window.location.reload();
//     }
//     // 响应状态码500，服务器错误
//     else if (error.response.status === 500) {
//       return Promise.reject(error.response.statusText)
//     }
//   }
// )

export default service
