<template>
  <div class="modular-service">
    <p class="title">
      <span>运维服务</span>
      <span>operation service</span>
    </p>

    <ul class="service-list">
      <li :class="{'service-item':true,active:index===activeService}" v-for="item,index in serviceList" :key="item.id" @click="switchService(index)">
        <div class="icon">
          <img :src="'/static/default-image/'+item.icon">
        </div>
        <div class="content">
          <p class="name">{{item.label}}</p>
          <p class="details">{{item.describe}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'service',
    components: {},
    props: {},
    data() {
      return {
        serviceList: [
          {
            label: "资源",
            describe: "独有的运维工具",
            icon: "resources.svg",
            id: 0
          },
          {
            label: "技术",
            describe: "高效的运维能力",
            icon: "technology.svg",
            id: 2
          },
          {
            label: "人员",
            describe: "强大的服务团队",
            icon: "personnel.svg",
            id: 3
          },
          {
            label: "过程",
            describe: "标准的服务闭环",
            icon: "process.svg",
            id: 4
          },
        ],
        activeService: 0
      }
    },
    methods: {
      switchService(index) {
        this.activeService = index
      }
    },
  }
</script>

<style lang="less">
  .modular-service {
    height: 350px;
    background-image: url(../../assets/home/service/service-background.png);
    background-size: cover;
    overflow: hidden;

    .title {
      text-align: center;
      color: #fff;
      margin-top: 24px;

      span:first-child {
        font-size: 18px;
        font-weight: 600;
        margin-right: 8px;
      }
      span:last-child {
        opacity: 0.9;
        font-size: 15px;
      }
    }

    .service-list {
      padding-left: 25px;
      margin-top: 30px;
      color: #fff;
    }

    .service-item {
      width: 52%;
      height: 52px;
      background-image: url(../../assets/home/service/service-item-background.png);
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 10px;
      margin-bottom: 10px;

      .icon {
        width: 34px;
        height: 34px;
        margin-right: 11px;
      }

      .content {
        opacity: 0.8;
        flex: 1;
      }

      .name {
        font-size: 15px;
      }

      .details {
        margin-top: 3px;
        font-size: 12px;
      }
    }

    .service-item.active {
      background: linear-gradient(90deg, #0d70f7 0%, #2998fe 100%);

      .content {
        opacity: 1;
      }
    }
  }
</style>
