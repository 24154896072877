import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: "主页",
    },
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/deadils',
    name: 'deadils',
    meta: {
      title: "",
    },
    component: () => import('@/views/deadils/index.vue')
  },
  {
    path: '/cases',
    name: 'cases',
    meta: {
      title: "经典案例",
    },
    component: () => import('@/views/cases/index.vue'),
  },
  {
    path: '/dynamic',
    name: 'dynamic',
    meta: {
      title: "康赛动态",
    },
    component: () => import('@/views/dynamic/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: "关于康赛",
    },
    component: () => import('@/views/about/index.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    meta: {
      title: "招贤纳士",
    },
    component: () => import('@/views/recruit/index.vue')
  },
  {
    path: '/join',
    name: 'join',
    meta: {
      title: "招商加盟",
    },
    component: () => import('@/views/join/index.vue')
  },
  {
    path: '/law',
    name: 'law',
    meta: {
      title: "法律声明",
    },
    component: () => import('@/views/law/index.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: {
      title: "服务",
    },
    component: () => import('@/views/service/index.vue')
  },
  {
    path: '/article',
    name: 'article',
    meta: {
      title: "",
    },
    component: () => import('@/views/article/index.vue')
  },
  {
    path: '/list',
    name: 'list',
    meta: {
      title: "",
    },
    component: () => import('@/views/list/index.vue')
  },
  {
    path: '*',
    redirect: "/"
  }
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { behavior: 'smooth', x: 0, y: 0 }
    }
  },
  routes
})

export default router