import http from '@/utils/request.js'

// 获取文章详情
export function getArticleDetail(params) {
  return http({
    url: "/artdetail",
    method: "POST",
    params: params
  })
}

// 获取文章列表
export function getArticleList(params) {
  return http({
    url: "/artlist",
    method: "POST",
    params: params
  })
}

// 获取文章列表
export function getArticleMenu(params) {
  return http({
    url: "/class",
    method: "POST",
    params: params
  })
}